import React from 'react'
import {
    GridItem,
    MuiBox,
    MuiDivider,
    MuiStack,
    MuiTypography,
    formatBRCurrency,
} from '../../../../components/storybook'
import { GetThemeColorFromStorage } from 'redux/api/themeColor'
import { numberFormatText } from '../../../../utils/utils'

export function CardBalance({
    currentTab,
    transferInfo,
    isSmallScreen,
    currentConsumer,
    generalRules,
}) {
    return (
        <GridItem
            xs={12}
            sm={12}
            md={6}
            lg={6}
            mt={
                (currentTab !== 'points' && generalRules?.allowTaxTransfer) ||
                (currentTab === 'points' &&
                    generalRules?.allowPointsWithdrawFee)
                    ? 1
                    : 3
            }
            sx={{
                paddingLeft: '0px !important',
            }}
        >
            <MuiBox
                sx={{
                    border: '1px solid #C4C4C4',
                    borderRadius: '5px',
                    padding: '10px 20px',
                    width: currentTab === 'points' ? '50%' : '100%',
                }}
            >
                {currentTab === 'points' ? (
                    <>
                        <MuiTypography fontSize="13px" mb={0.5} color="#717171">
                            Saldo disponível para saque
                        </MuiTypography>
                        <MuiTypography
                            fontSize="18px"
                            fontWeight={600}
                            color={
                                GetThemeColorFromStorage()?.navigation?.buttons
                                    ?.backgroundColor
                            }
                        >
                            {currentConsumer &&
                                numberFormatText(
                                    currentConsumer?.points,
                                    `${localStorage?.programCurrencySymbol} `,
                                    0,
                                )}
                        </MuiTypography>
                    </>
                ) : (
                    <MuiStack
                        direction={isSmallScreen ? 'column' : 'row'}
                        alignItems="center"
                        spacing={4}
                    >
                        <MuiBox>
                            <MuiTypography
                                fontSize="13px"
                                mb={0.5}
                                color="#717171"
                            >
                                Saldo na Carteira Digital
                            </MuiTypography>
                            <MuiTypography
                                fontSize="18px"
                                fontWeight={600}
                                color={
                                    GetThemeColorFromStorage()?.navigation
                                        ?.buttons?.backgroundColor
                                }
                            >
                                {formatBRCurrency(
                                    currentConsumer?.digitalWalletBalance,
                                )}
                            </MuiTypography>
                        </MuiBox>
                        {!isSmallScreen && (
                            <MuiDivider
                                orientation="vertical"
                                variant="middle"
                                flexItem
                            />
                        )}
                        <MuiBox>
                            <MuiTypography
                                fontSize="13px"
                                mb={0.5}
                                color="#717171"
                            >
                                Saldo disponível para saque
                            </MuiTypography>
                            <MuiTypography
                                fontSize="18px"
                                fontWeight={600}
                                color={
                                    GetThemeColorFromStorage()?.navigation
                                        ?.buttons?.backgroundColor
                                }
                            >
                                {formatBRCurrency(
                                    transferInfo?.balanceAvailable,
                                )}
                            </MuiTypography>
                        </MuiBox>
                    </MuiStack>
                )}
            </MuiBox>
        </GridItem>
    )
}
