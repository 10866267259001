/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment } from 'react'
import * as _ from 'lodash'
import Slider from 'react-slick'
import cx from 'classnames'
import { NavLink } from 'react-router-dom'


import { CustomButtonsBackground } from 'components/CustomButtons'
import GridItem from 'components/Grid/GridItem.jsx'


// Utils
import { numberFormatText } from 'utils/utils'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

// style
import styles from '../../home.module.scss'


// @Styles
import { Box, Skeleton } from '@mui/material'
import Section from '../../../../components/Section/Section'
import { CategoryImg, CategoryItem, CategoryName } from './styles'


export function Tiers({ generalRulesData, consumerProgress, tierGroupCategorization, categoryItems, loading, themeColor, t, settings }) {

	return (
		<GridItem
			xs={12}
			sm={12}
			md={6}
			style={{
				marginBottom: '30px',
			}}
		>
			<Section title={t('SECTION_TIERS')} />
			{categoryItems && !_.isEmpty(categoryItems) ? (
				<Fragment>
					<div id="category">
						<Slider {...settings}>
							{categoryItems?.map(
								(item, index) => [
									<Fragment>
										<CategoryItem key={index} >

											<CategoryName className={styles.categoryTitle} themeColor={themeColor}>
												{item?.categoryName}
											</CategoryName>


											<CategoryImg
												src={item?.url}
												alt={item?.categoryName}
												className={
													index <= tierGroupCategorization?.currentLevel
														? '' :
														styles.imageGrayscale}
											/>
											<Box className={styles.categoryScore}>
												{tierGroupCategorization?.category?.key === "0" ? (
													<Box display="flex" gap="2px">
														<Box component="span">
															{generalRulesData?.programCurrencySymbol || ''}
														</Box>
														<Box component="span">
															{numberFormatText(item?.scoreStart)}
														</Box>
													</Box>
												) : (
													item?.scoreStart?.toLocaleString('pt-BR', {
														style: 'currency',
														currency: 'BRL'
													}) || ''
												)}
											</Box>

										</CategoryItem>

										<Box
											p="22px 0px 14px 0px"
											className={cx({
												[styles.progressBarBack]: true,
												[styles.progressBarBackLast]:
													index ===
													categoryItems.length -
													1,
											})}
										>
											<Box
												component="div"
												height="15px"
												className={cx({
													[styles.progressbar]: true,
													[styles.firstProgressBar]:
														index ===
														0,
													[styles.lastProgressBar]:
														index ===
														categoryItems.length -
														1,
												})}
											>
												<div
													className={cx(
														{
															[styles.progressbarcolor]: true,
															[styles.firstProgressBarColor]:
																index ===
																0,
															[styles.lastProgressBarColor]:
																index ===
																categoryItems.length -
																1,
														},
													)}
													style={{
														width: `${consumerProgress.currentPercentage}%`,
														maxWidth:
															'100%',
														position:
															'absolute',
														height: '7%',
														left: '0px',
													}}
												/>
											</Box>
										</Box>
									</Fragment>,
								],
							)}
						</Slider>
					</div>

					<NavLink
						to={'/tiers'}
						style={{
							display: 'flex',
							justifyContent: 'center',
							marginTop: '18px',
						}}
					>
						<CustomButtonsBackground>
							Entenda os níveis
						</CustomButtonsBackground>
					</NavLink>
				</Fragment>
			) : loading ? (
				<Skeleton
					variant="rectangular"
					width="100%"
					height={150}
				/>
			) : (
				<div>
					<br />
					{t('RESULTS_NO_CATEGORY_FOUND')}
				</div>
			)}
		</GridItem>
	)
}

