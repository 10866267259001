import React from 'react'
import {
    GridContainer,
    GridItem,
    MuiTypography,
} from '../../../../components/storybook'
import { FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material'

export function OriginLoot({
    GetThemeColorFromStorage,
    currentTab,
    handleRadioChangeOrigin,
    generalRules,
}) {
    return (
        <GridContainer spacing={4} mt={1} ml={0} pl={0} pr={0}>
            <GridItem
                xs={12}
                sm={12}
                md={12}
                lg={12}
                mt={3}
                sx={{
                    paddingLeft: '0px !important',
                }}
            >
                <MuiTypography color="#333333" fontSize="14px" fontWeight={600}>
                    Selecione a origem do saque
                </MuiTypography>
            </GridItem>
            <GridItem
                xs={12}
                sm={12}
                md={12}
                lg={12}
                sx={{
                    paddingLeft: '0px !important',
                }}
            >
                <FormControl sx={{ width: '100%' }}>
                    <RadioGroup
                        value={currentTab}
                        onChange={handleRadioChangeOrigin}
                    >
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={6} lg={6}>
                                <FormControlLabel
                                    value="digitalWallet"
                                    control={
                                        <Radio
                                            sx={{
                                                color: GetThemeColorFromStorage()
                                                    ?.navigation?.buttons
                                                    ?.backgroundColor,
                                                fontSize: '25px',
                                                '&.Mui-checked': {
                                                    color: GetThemeColorFromStorage()
                                                        ?.navigation?.buttons
                                                        ?.backgroundColor,
                                                },
                                                '& .MuiSvgIcon-root': {
                                                    fontSize: 25,
                                                },
                                            }}
                                        />
                                    }
                                    label={
                                        <span
                                            style={{
                                                color: '#333333',
                                                fontWeight: 600,
                                                fontSize: '14px',
                                            }}
                                        >
                                            Carteira digital
                                        </span>
                                    }
                                />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={6} lg={6}>
                                <FormControlLabel
                                    value="points"
                                    control={
                                        <Radio
                                            sx={{
                                                color: GetThemeColorFromStorage()
                                                    ?.navigation?.buttons
                                                    ?.backgroundColor,
                                                fontSize: '25px',
                                                '&.Mui-checked': {
                                                    color: GetThemeColorFromStorage()
                                                        ?.navigation?.buttons
                                                        ?.backgroundColor,
                                                },
                                                '& .MuiSvgIcon-root': {
                                                    fontSize: 25,
                                                },
                                            }}
                                        />
                                    }
                                    label={
                                        <span
                                            style={{
                                                color: '#333333',
                                                fontWeight: 600,
                                                fontSize: '14px',
                                            }}
                                        >
                                            {generalRules?.programCurrencyName}
                                        </span>
                                    }
                                />
                            </GridItem>
                        </GridContainer>
                    </RadioGroup>
                </FormControl>
            </GridItem>
        </GridContainer>
    )
}
