import React, { Fragment, useCallback, useEffect, useState } from 'react'
import * as _ from 'lodash'
import DocumentTitle from 'react-document-title'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'

import Grid from '@material-ui/core/Grid'

import Loader from 'components/Loader/Loader.jsx'
import Section from 'components/Section/Section'

import styles from './styles.module.scss'
import compose from 'utils/compose'

import {
	GetActiveTierData,
	GetCurrentConsumer,
	ShowLoader,
	HideLoader,
} from 'redux/actions'
import { getProgramTitle } from '../../utils/utils'
import { Box, Typography } from '@mui/material'
import { CustomSkeleton } from './components/CustomSkeleton'
import { GetThemeColorFromStorage } from '../../redux/api/themeColor'
import { GetCurrentTierByConsumer } from '../../redux/api/tier'


const Category = (props) => {
	const { t, generalRulesData } = props
	const [loading, setLoading] = useState(null)
	const [themeColor] = useState(GetThemeColorFromStorage())
	const [consumerProgress, setConsumerProgress] = useState({})
	const [currentTier, setCurrentTier] = useState({})
	const [nextTier, setNextTier] = useState({})

	const CurrentNextTier = ({ points, tiers }) => {
		_.forEach(tiers, (t, i) => {
			if (points >= t.scoreStart) {
				if (!t.isFinalTier) {
					setNextTier(t)
				}
			} else {
				setNextTier(t)
				return false
			}
		})
	}

	const getCurrentTierByConsumer = useCallback(async () => {
		setLoading(true)
		try {
			const response = await GetCurrentTierByConsumer()

			if (response?.status === 200 && response?.data) {
				setConsumerProgress(response?.data)
				const tier = response?.data?.levels[response?.data?.currentLevel]

				console.log('tier', tier)

				setCurrentTier(tier)
				CurrentNextTier({
					points: response?.data?.consumerPoints,
					tiers: response?.data?.levels
				})
			}
		} catch (error) {
			console.log(error)
		} finally {
			setLoading(false)
		}
	}, [])


	useEffect(() => {
		getCurrentTierByConsumer()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		console.log('currentTier', currentTier)
		console.log('_.isEmpty(currentTier)', _.isEmpty(currentTier))
	}, [currentTier])

	return (
		<Grid>
			<DocumentTitle title={getProgramTitle(t('SECTION_TIERS'))} />
			<Section title={t('SECTION_TIERS')} />


			{!loading ?
				(<Fragment>
					<Box
						mb={2}
						display={_.isEmpty(currentTier) ? 'none' : 'flex'}
						height="81px"
						minWidth="300px"
						alignItems="center"
						borderRadius="5px"
						border="1px solid #eee"
						width="fit-content"
					>
						<Box
							sx={{
								padding: '20px',
								display: 'flex',
								flexDirection: 'column',
								alignItems: 'center',
								justifyContent: 'center',
								height: '100%',
								minWidth: '145px',
								borderRight: '1px solid #eee',
								borderRadius: '5px 0 0 5px',
							}}
						>
							<Typography variant="body1" fontSize={13}>
								Seu nível atual
							</Typography>
							<Typography variant="body1" sx={{ fontWeight: 700, color: themeColor?.titles?.main?.textColor }}>
								{currentTier?.name}
							</Typography>
						</Box>

						<Box
							sx={{
								padding: '3px',
								minWidth: '145px',
								height: '100%',
								borderRadius: '0 5px 5px 0',
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
							}}
						>
							<img
								src={currentTier?.iconUrl}
								alt={currentTier?.name || 'Imagem do nível atual'}
								style={{
									maxWidth: '100%',
									maxHeight: '100%',
									objectFit: 'contain',
								}}
							/>
						</Box>
					</Box>

					<br />
					{consumerProgress?.levels?.length !== consumerProgress?.currentLevel + 1 && (
						<Box component="p" className={styles.Boxtext} mb={2}>
							Para alcançar o nível{' '}
							<span style={{ color: themeColor?.titles?.main?.textColor, fontWeight: 'bold' }}>
								{nextTier.name}
							</span>
							, você precisa atingir{' '}
							{consumerProgress?.tierGroupCategorization?.key === "0"
								? `${generalRulesData.programCurrencySymbol} ${(nextTier.scoreStart - consumerProgress?.consumerPoints)?.toLocaleString()}`
								: `${(nextTier.scoreStart - consumerProgress?.consumerPoints)?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}`
							}.
						</Box>
					)}


					{consumerProgress?.levels && (
						<Fragment>
							{consumerProgress?.levels?.map((item) => (
								<div
									style={{
										display: 'flex',
										padding: '20px 0',
									}}
									key={item.id}
								>
									<Grid
										item
										md={2}
										sm={2}
										xs={2}
										className={styles.imgBox}
									>
										<Box
											component="img"
											src={item.iconUrl}
											alt={item.name}
											sx={{
												maxWidth: '100px',
												height: '99px'
											}}
										/>
									</Grid>
									<Grid
										item
										md={11}
										sm={11}
										xs={11}
										className={styles.textBox}
									>
										<Box
											component="span"
											className={styles.title}
											sx={{ color: themeColor?.titles?.main?.textColor }}
										>
											{item.name}
										</Box>
										<br />
										{item.scoreStart && item.scoreEnd ? (
											<Box component="p" fontWeight={700} color="#333333">
												{consumerProgress?.tierGroupCategorization.key === "0"
													? `${generalRulesData.programCurrencySymbol} ${item.scoreStart?.toLocaleString()} - ${generalRulesData.programCurrencySymbol} ${item.scoreEnd?.toLocaleString()}`
													: `${item.scoreStart?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })} - ${item.scoreEnd?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}`
												}
											</Box>
										) : (
											<Box component="p" fontWeight={700} color="#333333">
												{consumerProgress?.tierGroupCategorization.key === "0"
													? `até  ${generalRulesData.programCurrencySymbol} a ${item.scoreEnd?.toLocaleString()}`
													: `até ${item.scoreEnd?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}`
												}
											</Box>
										)}
										<br />
										{item.description}
									</Grid>
								</div>
							))}
						</Fragment>
					)}

				</Fragment>
				) : (
					<CustomSkeleton />
				)
			}
		</Grid>
	)
}


const mapStateToProps = (state) => {
	return {
		tierData: state.tier.tierData,
		tierDataFailed: state.tier.tierDataFailed,
		loading: state.loader.loading,
		activeTierData: state.tier.activeTierData,
		activeTierDataFailed: state.tier.activeTierDataFailed,
		userData: state.users.userData,
		generalRulesData: state.generalRules.generalRulesData,
	}
}

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{ GetActiveTierData, GetCurrentConsumer, ShowLoader, HideLoader },
		dispatch,
	)

export default compose(
	connect(mapStateToProps, mapDispatchToProps),
	withTranslation(),
)(Category)



