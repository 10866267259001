import React, { useEffect } from 'react'
import {
    GridContainer,
    GridItem,
    InputPtBRCurrency,
    InputUSDCurrency,
    LabelText,
    MuiLoader,
    formatBRCurrency,
} from '../../../../components/storybook'
import { Controller } from 'react-hook-form'
import {
    formatPercentage,
    formatValue,
    formatValuePoint,
} from '../../functions'

export function TransferValue({
    GetThemeColorFromStorage,
    control,
    watchFields,
    currentTab,
    getTransferTax,
    transferTax,
    errorMessageValue,
    generalRules,
    debouncedValue,
    setDebouncedValue,
    watchValue,
    isSmallScreen,
    isLoadingValue,
}) {
    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedValue(watchValue)
        }, 2000)
        return () => {
            clearTimeout(handler)
        }
    }, [watchValue])

    useEffect(() => {
        if (debouncedValue) {
            if (currentTab === 'points') {
                getTransferTax(formatValuePoint(debouncedValue), currentTab)
            } else {
                getTransferTax(formatValue(debouncedValue), currentTab)
            }
        }
    }, [debouncedValue])

    return (
        <GridContainer spacing={2} mt={3} pl={0} pr={0}>
            <GridItem
                xs={12}
                sm={12}
                md={3}
                lg={3}
                sx={{ paddingRight: isSmallScreen && '0px !important' }}
            >
                {currentTab === 'points' ? (
                    <Controller
                        name="valuePoint"
                        control={control}
                        defaultValue=""
                        render={({ ...field }) => (
                            <>
                                <InputUSDCurrency
                                    {...field}
                                    placeholder={
                                        generalRules?.programCurrencySymbol
                                    }
                                    textLabel="Valor do saque"
                                    showLabel
                                    defaultColor={
                                        GetThemeColorFromStorage()?.navigation
                                            ?.buttons?.backgroundColor
                                    }
                                    value={watchFields?.valuePoint ?? ''}
                                    error={!!errorMessageValue}
                                    helperText={errorMessageValue ?? ''}
                                    maskedValue={
                                        generalRules?.programCurrencySymbol
                                    }
                                />
                            </>
                        )}
                    />
                ) : (
                    <Controller
                        name="value"
                        control={control}
                        defaultValue=""
                        render={({ ...field }) => (
                            <>
                                <InputPtBRCurrency
                                    {...field}
                                    placeholder="R$"
                                    textLabel="Valor do saque"
                                    showLabel
                                    defaultColor={
                                        GetThemeColorFromStorage()?.navigation
                                            ?.buttons?.backgroundColor
                                    }
                                    value={watchFields?.value ?? ''}
                                    error={!!errorMessageValue}
                                    helperText={errorMessageValue ?? ''}
                                />
                            </>
                        )}
                    />
                )}
            </GridItem>
            {isLoadingValue ? (
                <GridItem xs={12} sm={12} md={8} lg={8} mb={2} mt={0.5}>
                    <MuiLoader
                        defaultColor={
                            GetThemeColorFromStorage()?.navigation?.buttons
                                ?.backgroundColor
                        }
                    />
                </GridItem>
            ) : (
                <>
                    {debouncedValue && (
                        <>
                            {currentTab !== 'points' &&
                                generalRules?.allowTaxTransfer && (
                                    <GridItem
                                        xs={12}
                                        sm={12}
                                        md={3}
                                        lg={3}
                                        mb={2}
                                        mt={0.5}
                                    >
                                        <LabelText
                                            label="Taxa do saque"
                                            value={
                                                <span
                                                    style={{
                                                        color: GetThemeColorFromStorage()
                                                            ?.navigation
                                                            ?.buttons
                                                            ?.backgroundColor,
                                                        fontWeight: 600,
                                                        fontSize: '15px',
                                                    }}
                                                >
                                                    {(transferTax?.transferFee ===
                                                        null &&
                                                        transferTax?.transferFeePercentage) !==
                                                        0 &&
                                                    transferTax?.transferFeePercentage !==
                                                        null
                                                        ? formatPercentage(
                                                              transferTax?.transferFeePercentage ??
                                                                  0,
                                                          )
                                                        : transferTax?.transferFee !==
                                                              undefined &&
                                                          transferTax?.transferFee !==
                                                              null
                                                        ? formatBRCurrency(
                                                              transferTax?.transferFee,
                                                          )
                                                        : '-'}
                                                </span>
                                            }
                                        />
                                    </GridItem>
                                )}
                            {currentTab === 'points' &&
                                generalRules?.allowPointsWithdrawFee && (
                                    <GridItem
                                        xs={12}
                                        sm={12}
                                        md={3}
                                        lg={3}
                                        mb={2}
                                        mt={0.5}
                                    >
                                        <LabelText
                                            label="Taxa do saque"
                                            value={
                                                <span
                                                    style={{
                                                        color: GetThemeColorFromStorage()
                                                            ?.navigation
                                                            ?.buttons
                                                            ?.backgroundColor,
                                                        fontWeight: 600,
                                                        fontSize: '15px',
                                                    }}
                                                >
                                                    {transferTax?.transferFeePercentage !==
                                                        0 &&
                                                    transferTax?.transferFeePercentage !==
                                                        null
                                                        ? formatPercentage(
                                                              transferTax?.transferFeePercentage ??
                                                                  0,
                                                          )
                                                        : transferTax?.transferFee !==
                                                              undefined &&
                                                          transferTax?.transferFee !==
                                                              null
                                                        ? formatBRCurrency(
                                                              transferTax?.transferFee,
                                                          )
                                                        : '-'}
                                                </span>
                                            }
                                        />
                                    </GridItem>
                                )}
                            {currentTab !== 'points' &&
                                generalRules?.allowAntecipationTax &&
                                transferTax?.anticipationFees?.length > 0 &&
                                transferTax?.anticipationFees.map(
                                    (item, index) => (
                                        <GridItem
                                            xs={12}
                                            sm={12}
                                            md={3}
                                            lg={3}
                                            mb={2}
                                            mt={0.5}
                                            key={index}
                                        >
                                            <LabelText
                                                label="Taxa de antecipação"
                                                value={
                                                    <span
                                                        style={{
                                                            color: GetThemeColorFromStorage()
                                                                ?.navigation
                                                                ?.buttons
                                                                ?.backgroundColor,
                                                            fontWeight: 600,
                                                            fontSize: '15px',
                                                        }}
                                                    >
                                                        {item?.anticipationFee
                                                            ? formatBRCurrency(
                                                                  item?.anticipationFee,
                                                              )
                                                            : formatPercentage(
                                                                  item?.anticipationFeePercentage,
                                                              )}
                                                    </span>
                                                }
                                            />
                                        </GridItem>
                                    ),
                                )}

                            <GridItem
                                xs={12}
                                sm={12}
                                md={3}
                                lg={3}
                                mb={2}
                                mt={0.5}
                            >
                                <LabelText
                                    label="Valor Líquido"
                                    value={
                                        <span
                                            style={{
                                                color: GetThemeColorFromStorage()
                                                    ?.navigation?.buttons
                                                    ?.backgroundColor,
                                                fontWeight: 600,
                                                fontSize: '18px',
                                            }}
                                        >
                                            {transferTax?.liquidValue
                                                ? formatBRCurrency(
                                                      transferTax?.liquidValue,
                                                  )
                                                : '-'}
                                        </span>
                                    }
                                />
                            </GridItem>
                        </>
                    )}
                </>
            )}
        </GridContainer>
    )
}
