import React from 'react'
import { Button, MuiBox, MuiLoader, MuiSnack } from '../../components/storybook'
import DocumentTitle from 'react-document-title'
import Section from '../../components/Section/Section'
import { getProgramTitle } from '../../utils/utils.js'
import { Modal } from './components/Modal/index.jsx'
import { DigitalWallet } from './components/DigitalWallet/index.jsx'
import { ConfirmRefundModal } from './components/ConfirmRefundModal/index.jsx'
import { ConfirmPasswordModal } from './components/ConfirmPasswordModal/index.jsx'
import { CodeModal } from './components/CodeModal/index.jsx'
import { MessageSuccess } from './components/MessageSuccess/index.jsx'
import { NoPaymentOptionModal } from './components/NoPaymentOptionModal/index.jsx'
import { InsufficientAccountModal } from './components/InsufficientAccountModal/index.jsx'

const TemplatePage = ({ ...sharedProps }) => {
    const {
        handleHomeClick,
        GetThemeColorFromStorage,
        setIsModalConfirmOpen,
        messageSuccess,
        setMessageSuccess,
        errorMessage,
        setErrorMessage,
        errorMessageNotFound,
        isButtonDisabled,
        errors,
        isLoading,
        validateValue,
    } = sharedProps

    return (
        <div>
            <DocumentTitle title={getProgramTitle('Solicitação de saque')} />
            <MuiBox mt={1}>
                <>
                    {isLoading ? (
                        <MuiLoader
                            defaultColor={
                                GetThemeColorFromStorage()?.navigation?.buttons
                                    ?.backgroundColor
                            }
                        />
                    ) : (
                        <>
                            {messageSuccess ? (
                                <MessageSuccess
                                    handleHomeClick={handleHomeClick}
                                    setMessageSuccess={setMessageSuccess}
                                />
                            ) : (
                                <>
                                    <MuiSnack
                                        open={errorMessage}
                                        message={errorMessage}
                                        type="error"
                                        autoHideDuration={5000}
                                        onClose={() => setErrorMessage('')}
                                        anchorOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}
                                    />
                                    <Section
                                        title="Solicitação de saque"
                                        children={
                                            !messageSuccess && (
                                                <Button
                                                    border={`1px solid ${
                                                        GetThemeColorFromStorage()
                                                            ?.navigation
                                                            ?.buttons
                                                            ?.backgroundColor
                                                    }`}
                                                    defaultColor={
                                                        GetThemeColorFromStorage()
                                                            ?.navigation
                                                            ?.buttons
                                                            ?.backgroundColor
                                                    }
                                                    icon=""
                                                    outlined="outlined"
                                                    text="Sacar"
                                                    textColor="#FFFFFF"
                                                    typeVariation="CustomTextButton"
                                                    borderRadius="5px"
                                                    onClick={() =>
                                                        setIsModalConfirmOpen(
                                                            true,
                                                        )
                                                    }
                                                    disabled={
                                                        isButtonDisabled ||
                                                        !validateValue(errors)
                                                    }
                                                />
                                            )
                                        }
                                    />

                                    {errorMessageNotFound ? (
                                        <NoPaymentOptionModal
                                            {...sharedProps}
                                        />
                                    ) : (
                                        <>
                                            {/************** MAIN COMPONENT **************/}
                                            <DigitalWallet {...sharedProps} />

                                            {/************** MODAL USETERMS **************/}
                                            <Modal {...sharedProps} />

                                            {/************** MODAL CONFIRM PASSWORD **************/}
                                            <ConfirmRefundModal
                                                {...sharedProps}
                                            />

                                            {/************** MODAL PASSWORD CONFIRMED **************/}
                                            <ConfirmPasswordModal
                                                {...sharedProps}
                                            />

                                            {/************** MODAL CODE **************/}
                                            <CodeModal {...sharedProps} />

                                            {/************** MODAL INSUFFICIENT ACCOUNT **************/}
                                            <InsufficientAccountModal
                                                {...sharedProps}
                                            />
                                        </>
                                    )}
                                </>
                            )}
                        </>
                    )}
                </>
            </MuiBox>
        </div>
    )
}

export default TemplatePage
